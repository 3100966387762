html {
  font-family: 'proxima-nova', sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: 'proxima-nova', sans-serif;
}

.container {
  display: grid;
}

input.form-control {
  color: #1D1D1D;
}

h2 {
  font-family: 'retiro-std-24pt';
  font-size: 38px;
  color: '#1D1D1D';
  text-transform: uppercase;
}

h4 {
  font-family: 'proxima-nova', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  color: "#000000";
  text-decoration: none;
}

h5 {
  font-family: 'proxima-nova', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}

a:hover, a:active, .btn-link:hover {
  text-decoration: none;
}

.bm-burger-bars {
  opacity: 1 !important;
}

a {
  color: #707070
}

.search-item-image{
  max-width: min-content;
}

.ReactModal__Overlay, .ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2100;
}

.ReactModal__Content, .ReactModal__Content.ReactModal__Content--after-open {
  position: initial;
  border-radius: 0 !important;
  width: 775px;
  margin: 0 auto;
  top: 0 !important;
  bottom: 0 !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  padding-top: 0px !important;
  padding-bottom: 30px !important;
  z-index: 2200;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

@media(max-width:1000px) {
  .SignInForm.container,
  .require-new-password-container.container,
  .change-password-container.container {
    max-width: 80% !important;
    min-width: 80% !important;
    width: 80% !important;
  }
}

@media(min-width:1001px) {
  .SignInForm.container,
  .require-new-password-container.container,
  .change-password-container.container {
     height: 520px;
     min-height: 400px;
  }
}

input[type="date"]::-webkit-inner-spin-button{
  display: none;
}

.shipments-filter input[type="date"]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

.shipmentLogs-filter input[type="date"]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

.obj-no-images-filter input[type="date"]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

.checkin-objects-form input[type="date"]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

.form-header {
  position: -webkit-sticky;
  position: sticky;
}

.react-datepicker-wrapper input {
  width: 160px;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-datepicker-wrapper input.image-management-date-filter {
  width: 115px;
}

@media only screen and (min-width: 768px) {
  .navbar-fixed-top{
    width: 100%;
  }

  .navbar-brand{
    border-right: 1px solid rgb(131,131,131);
  }

  .app-body{
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .no-image-div{
    height: 225px;
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .Footer{
    height: 5em !important;
  }

  .Footer ul li:first-child{
    padding-left: 0px !important;
  }

  .app-body{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .search-item-image{
    max-width: min-content;
  }

  .no-image-div{
    height: 26em;
    width: 80%;
  }

  .view-item-image{
    width: 80%;
  }

  h2 {
    font-size: 26px !important;
  }

  h3 {
    font-size: 16px !important;
  }

  nav.navbar-fixed-top.navbar.navbar-expand-md {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .navbar-fixed-top.navbar.navbar-expand-md > div {
    max-width: 100% !important;
  }

  .client-chooser-wrapper {
    margin-left: 0 !important;
    max-width: 100%;
  }

  .css-2sbac7 {
    max-width: 100% !important;
  }

}

